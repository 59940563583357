import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a4d25488"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w_1200" }
const _hoisted_2 = { class: "bread" }
const _hoisted_3 = { class: "detail_box" }
const _hoisted_4 = { class: "detail_left_box" }
const _hoisted_5 = { class: "detail_content_box" }
const _hoisted_6 = { class: "detail_right_box" }

import breadVue from "../../bread.vue/bread.vue"; // 引入面包屑
import detailLeftVue from "./detailLeft/detailLeft.vue"; // 左侧内容
import detailCentent from "./detailContent/detailContent.vue"; // 中间内容
import detailRight from "./detailRight/detailRight.vue"; // 右边部分
import { getDetailNews } from "@/api/modules/news_api.js";
import { useRoute } from "vue-router";
import { onMounted, reactive, ref, watch } from "vue";
import { useNewsDetail } from "@/pinia/api/useDetailOffer.js";
import { useDetail } from "@/pinia/api/useDetail.js";

export default {
  setup(__props) {

const route = useRoute();
const detailStore = useDetail();
const data = reactive({
  // 改条商机的详情页
  info: {},
});
sessionStorage.setItem("detail_current_nav", "商机详情");
// 点击其他商机信息时触发
const readMoreNews = async (id) => {
  loading.value = true;
  let res = await getDetailNews({ id });
  if (res.status === 1) {
    res.data.newsInfo.goods.forEach((v, i) => {
      if (!v.goods_img && res.data.newsInfo.goodsImg?.length) {
        v.goods_img = res.data.newsInfo.goodsImg?.[i]["图片"];
      }
    });
    data.info = res.data.newsInfo;
    loading.value = false;
  }
};

const loading = ref(false);

const getTId = () => {
  return route.query.id;
};

const init = async () => {
  loading.value = true;
  let res = await getDetailNews({ id: getTId() });
  console.log(res);
  if (res.status === 1) {
    res.data.newsInfo.goods.forEach((v, i) => {
      if (!v.goods_img && res.data.newsInfo.goodsImg?.length) {
        v.goods_img = res.data.newsInfo.goodsImg?.[i]["图片"];
      }
    });
    data.info = res.data.newsInfo;
    loading.value = false;
  }
};

watch(
  route,
  () => {
    init();
  },
  { immediate: true }
);

return (_ctx, _cache) => {
  const _directive_loading = _resolveDirective("loading")

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(breadVue)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(detailLeftVue, {
          info: _unref(data).info
        }, null, 8, ["info"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(detailCentent, {
          info: _unref(data).info
        }, null, 8, ["info"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(detailRight, {
          info: _unref(data).info,
          onReadMoreNews: readMoreNews
        }, null, 8, ["info"])
      ])
    ])
  ])), [
    [_directive_loading, loading.value]
  ])
}
}

}