

import http from '../axios'


// 贸易商及
export const getNewsData = (params) => {
    return http.post('/news', params)
}

// 贸易商机更多报价
export const getNewsMore = params => {
    return http.post('/news/more', params,)
}

// 贸易商机详情页
export const getDetailNews = params => {
    return http.post('/news/info', params)
}